import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  AccordionProps,
  AccordionSummaryProps,
} from "components/ui/accordion";
import { Typography } from "components/ui/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { MainBackgroundWrapper } from "modules/non-protected-page/shared";
import { styled } from "@mui/material/styles";
import { List, ListItem } from "components/ui/list/List";
import { Container } from "../marketing-pages/front-page/shared";
import React from "react";
import { trackClickOnFaq } from "utils/tracking/common";
import { slugify } from "client-server-shared/utils/text-utils";
import useSearchParam from "external/react-use/useSearchParam";
import { useRouter } from "next/router";
import { useCommonTranslation } from "components/translate";
import { BoxProps } from "components/ui/box";

export const StyledAccordion = styled(
  (props: AccordionProps & { variant: Variant }) => (
    <Accordion disableGutters elevation={0} square {...props} />
  )
)(({ theme, variant }) => ({
  border: `1px solid rgb(44 51 73)`,
  background: variant === "secondary" ? "initial" : "transparent",
  borderRadius: "12px",
  color: variant === "secondary" ? "initial" : "white",
  width: "100%",
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

export const StyledAccordionSummary = styled(
  (props: AccordionSummaryProps & { variant: Variant }) => (
    <AccordionSummary {...props} />
  )
)(({ theme, variant }) => ({
  "& .MuiAccordionSummary-expandIconWrapper": {
    backgroundColor: variant === "secondary" ? "initial" : "white",
    borderRadius: "50%",
    "& svg": {
      fill: "black",
      minWidth: "24px",
      minHeight: "24px",
      height: "24px",
      width: "24px",
    },
  },
}));

export const StyledHeader = styled(Typography)(({ variant }) => ({
  fontWeight: variant === "secondary" ? 500 : 700,
}));

export const StyledAnswer = styled(Typography)(({ theme, variant }) => ({
  color: variant === "secondary" ? "initial" : "#D4DCE5",
  fontWeight: variant === "secondary" ? 400 : 500,
  "& a": {
    color: variant === "secondary" ? "initial" : "white",
  },
}));

type Variant = "primary" | "secondary";

interface Props {
  faqLists: { question: string; answer: string }[];
  variant?: Variant;
  rootProps?: BoxProps;
}

const ListItemContainer = React.memo(
  (list: {
    question: string;
    answer: string;
    info?: string;
    variant: Variant;
  }) => {
    const [open, setOpen] = React.useState(false);
    const router = useRouter();
    const ref = React.useRef(null);
    const hasScrollInToView = React.useRef(false);
    const selectedSection = router.query?.section as string | undefined;
    React.useEffect(() => {
      if (hasScrollInToView.current) {
        return;
      }
      if (selectedSection === slugify(list.question)) {
        setOpen(true);
        if (ref && ref.current) {
          hasScrollInToView.current = true;
          setTimeout(() => {
            try {
              const top =
                ref.current.getBoundingClientRect().top +
                window.pageYOffset -
                100;
              window.scrollTo({ top, behavior: "smooth" });
            } catch (e) {}
          }, 100);
        }
      }
    }, [selectedSection, ref]);
    return (
      <ListItem
        ref={ref}
        key={list.question}
        sx={{
          paddingTop: "12px",
          paddingBottom: "12px",
        }}
      >
        <StyledAccordion
          variant={list.variant}
          expanded={open}
          key={list.question}
        >
          <StyledAccordionSummary
            variant={list.variant}
            sx={{
              userSelect: "initial",
            }}
            onClick={() => {
              setOpen((previous) => !previous);
              trackClickOnFaq({
                question: list.question,
              });
            }}
            expandIcon={<ExpandMoreIcon />}
          >
            <StyledHeader variant={list.variant}>{list.question}</StyledHeader>
          </StyledAccordionSummary>
          <AccordionDetails>
            <StyledAnswer
              variant={list.variant}
              dangerouslySetInnerHTML={{ __html: list.answer }}
            ></StyledAnswer>
          </AccordionDetails>
        </StyledAccordion>
      </ListItem>
    );
  }
);

const FaqList = React.memo(
  ({
    faqLists,
    variant = "primary",
    rootProps = {},
    dsiableBackground,
  }: Props & { dsiableBackground?: boolean }) => {
    const { t } = useCommonTranslation();
    return (
      <>
        <MainBackgroundWrapper
          dsiableBackground={dsiableBackground}
          component="section"
          {...rootProps}
          sx={{
            paddingTop: "80px",
            paddingBottom: "148px",
            rowGap: "24px",
            flexDirection: {
              xs: "column",
              sm: `column`,
              md: `row`,
              xl: `row`,
            },
            ...(variant === "secondary" && {
              backgroundColor: "initial",
            }),
            ...(rootProps.sx || {}),
          }}
        >
          <Container>
            <Typography
              variant="h6"
              id="faq"
              textAlign={"center"}
              sx={{
                fontSize: "1.5rem",
                color: variant === "secondary" ? "initial" : "text.white",
                padding: "48px 0",
              }}
            >
              {t("Frequently asked questions")}
            </Typography>
            <List>
              {faqLists.map((list) => {
                return (
                  <ListItemContainer
                    variant={variant}
                    key={list.question}
                    {...list}
                  />
                );
              })}
            </List>
          </Container>
        </MainBackgroundWrapper>
      </>
    );
  }
);

export default FaqList;
