import * as React from "react";
import { TemplateCardCompactPrimary } from "components/ui/card";
import { Box, BoxProps } from "components/ui/box";
import { useTheme } from "@mui/material/styles";
import {
  useOnFilterTemplatesByCategory,
  useOnFilterTemplatesByKeywords,
  useSelectSelectedCategory,
  useSelectTemplates,
  useSelectValue,
  useSelectSelectedTemplateIds,
  useSelectTemplateIds,
  useOnTemplateSelected,
} from "context/templates-select-context";
import { templateCategories } from "templates/shared-templates/types";
import { Button } from "components/ui/button";
import { useTranslation } from "components/translate";
import { PrimaryInput } from "components/input";
import { OutlineGreyButton } from "components/ui/button/button-with-loading";

const CategoryTags = () => {
  const theme = useTheme();
  const onFilterTemplatesByCategory = useOnFilterTemplatesByCategory();
  const selectedCategory = useSelectSelectedCategory();
  return (
    <Box
      component="nav"
      marginTop={10}
      marginBottom={10}
      sx={{
        display: "flex",
        flexWrap: "wrap",
        flexDirection: {
          xs: "row",
          sm: "row",
          md: "row",
          lg: "column",
        },
        rowGap: "24px",
        alignItems: "flex-start",
      }}
    >
      {templateCategories.map((category) => {
        const selected = selectedCategory === category;
        return (
          <OutlineGreyButton
            key={category}
            asText
            sx={{
              border: "none",
              color: theme.palette.text.primary,
              backgroundColor: selected
                ? `${theme.palette.background.softPrimary}!important`
                : "none",
              fontSize: "14px",
              marginRight: "3px",
              borderRadius: "20px",
              fontWeight: 500,
            }}
            onClick={() => onFilterTemplatesByCategory(category)}
          >
            {category}
          </OutlineGreyButton>
        );
      })}
    </Box>
  );
};

interface Props {
  asLink?: boolean;
  templateListProps?: BoxProps;
}

export const TemplatesCompactLibrary = ({
  asLink = false,
  templateListProps = {},
}: Props) => {
  const value = useSelectValue();
  const onFilterTemplatesByKeywords = useOnFilterTemplatesByKeywords();

  const onInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onFilterTemplatesByKeywords(event.target.value);
  };
  const { t } = useTranslation("templates");

  const templates = useSelectTemplates();
  const selectedTemplateIds = useSelectSelectedTemplateIds();
  const onSelectTemplateIds = useSelectTemplateIds();
  const onTemplateSelected = useOnTemplateSelected();
  return (
    <Box
      sx={{
        display: "flex",
        columnGap: "48px",
        flexDirection: {
          xs: "column",
          sm: "column",
          md: "column",
          lg: "row",
        },
      }}
    >
      <CategoryTags />

      <Box
        {...templateListProps}
        sx={{
          display: "flex",
          flexWrap: "wrap",
          height: "100%",
          overflow: "auto",
          ...(templateListProps.sx || {}),
        }}
      >
        {templates.length > 0
          ? templates.map((template) => {
              return (
                <TemplateCardCompactPrimary
                  key={template.id}
                  asLink={asLink}
                  {...template}
                  selected={selectedTemplateIds.includes(template.id)}
                  onClick={() => {
                    onSelectTemplateIds([template.id]);
                    if (onTemplateSelected) {
                      onTemplateSelected(template);
                    }
                  }}
                />
              );
            })
          : null}
      </Box>
    </Box>
  );
};
