import FaqList from "modules/non-protected-page/faq-list";
import React from "react";
import { useTranslation } from "components/translate";

export const useFaqLists = () => {
  const { t } = useTranslation("templates");
  const faqLists = [
    {
      question: t("faq.customizeTemplates.question"),
      answer: t("faq.customizeTemplates.answer"),
    },
    {
      question: t("faq.updateTemplates.question"),
      answer: t("faq.updateTemplates.answer"),
    },
    {
      question: t("faq.compatibleCMS.question"),
      answer: t("faq.compatibleCMS.answer"),
    },
    {
      question: t("faq.multipleLanguages.question"),
      answer: t("faq.multipleLanguages.answer"),
    },
    {
      question: t("faq.optimizedSEO.question"),
      answer: t("faq.optimizedSEO.answer"),
    },
    {
      question: t("faq.requestTemplate.question"),
      answer: t("faq.requestTemplate.answer"),
    },
    {
      question: t("faq.workForIndustries.question"),
      answer: t("faq.workForIndustries.answer"),
    },
    {
      question: t("faq.useForProjects.question"),
      answer: t("faq.useForProjects.answer"),
    },
  ];
  return faqLists;
};

const Faq = React.memo(() => {
  const faqLists = useFaqLists();
  return <FaqList faqLists={faqLists} />;
});

export default Faq;
