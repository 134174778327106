import { useTranslateTemplates } from "hooks/use-translate-templates";
import { templateCategory } from "templates/shared-templates/types";
import * as React from "react";
import { TabValue } from "modules/templates/types";
import type { Template } from "client-server-shared/types/collection";

interface TemplatesSelectContextContextValue {
  selectedCategory: TabValue;
  value: string;
  selectedTemplateIds: string[];
  onSelectTemplateByIds: (templateIds: string[]) => void;
  templates: Template[];
  onFilterTemplatesByCategory: (category: TabValue) => void;
  onFilterTemplatesByKeywords: (category: string) => void;
  onTemplateSelected?: Props["onTemplateSelected"];
}

const TemplatesSelectContext =
  React.createContext<TemplatesSelectContextContextValue | null>(null);

export const useTemplatesSelectContext = () => {
  const context = React.useContext(TemplatesSelectContext);
  if (!context) {
    throw new Error(
      "TemplatesSelectContext needs to be used with TemplatesSelectContextProvider"
    );
  }
  return context;
};

interface Props {
  selectedTemplateIds?: string[];
  onSelectTemplateByIds?: (templateIds: string[]) => void;
  onTemplateSelected?: (template: Template) => void;
  children: React.ReactNode;
  clientTemplates: Template;
}

const emptyArray: string[] = [];

export const TemplatesSelectContextProvider = React.memo((props: Props) => {
  const { translates } = useTranslateTemplates();
  const allTemplates = React.useMemo(() => {
    return translates(props.clientTemplates);
  }, [translates, props.clientTemplates]);
  const [selectedTemplateIds, setSelectedTemplateIds] = React.useState(
    props.selectedTemplateIds || emptyArray
  );

  const [value, setValue] = React.useState("");

  const [selectedCategory, setSelectedCategory] = React.useState(
    templateCategory.all
  );

  React.useEffect(() => {
    if (props.selectedTemplateIds) {
      setSelectedTemplateIds(props.selectedTemplateIds);
    }
  }, [props.selectedTemplateIds]);

  const templates = React.useMemo(() => {
    let filteredTemplates = [...allTemplates];
    const categoryToLower = selectedCategory.toLowerCase();
    const valueToLower = value ? value.toLowerCase().trim() : "";
    if (selectedCategory !== templateCategory.all) {
      filteredTemplates = allTemplates.filter((template) => {
        return template.categories
          .map((category) => category.toLowerCase())
          .includes(categoryToLower);
      });
    }
    if (valueToLower) {
      filteredTemplates = allTemplates.filter((template) => {
        return (
          template.cardDescription.toLowerCase().includes(valueToLower) ||
          template.categories
            .map((category) => category.toLowerCase())
            .includes(valueToLower) ||
          template.title.toLowerCase().includes(valueToLower)
        );
      });
    }
    return filteredTemplates;
  }, [selectedCategory, value]);
  const onFilterTemplatesByCategory = React.useCallback(
    (category: TabValue) => {
      setSelectedCategory(category);
    },
    []
  );
  const onFilterTemplatesByKeywords = React.useCallback((value: string) => {
    setValue(value);
  }, []);

  const onSelectTemplateByIds = React.useCallback((templateIds: string[]) => {
    const idsToAdd: string[] = [];
    const idsToRemoved: string[] = [];
    templateIds.filter((newTemplateId) => {
      if (selectedTemplateIds.includes(newTemplateId)) {
        idsToRemoved.push(newTemplateId);
      } else {
        idsToAdd.push(newTemplateId);
      }
    });
    const newTemplateIds = [
      ...selectedTemplateIds.filter((id) => !idsToRemoved.includes(id)),
      ...idsToAdd,
    ];

    if (props.onSelectTemplateByIds) {
      props.onSelectTemplateByIds(newTemplateIds);
    }
    setSelectedTemplateIds(newTemplateIds);
  }, []);

  const values = {
    value,
    selectedCategory,
    selectedTemplateIds,
    onSelectTemplateByIds,
    templates,
    onFilterTemplatesByCategory,
    onFilterTemplatesByKeywords,
    onTemplateSelected: props.onTemplateSelected,
  };
  return (
    <TemplatesSelectContext.Provider value={values}>
      {props.children}
    </TemplatesSelectContext.Provider>
  );
});

export const useSelectSelectedTemplateIds = () =>
  useTemplatesSelectContext().selectedTemplateIds;
export const useSelectTemplateIds = () =>
  useTemplatesSelectContext().onSelectTemplateByIds;

export const useSelectSelectedCategory = () =>
  useTemplatesSelectContext().selectedCategory;
export const useSelectTemplates = () => useTemplatesSelectContext().templates;
export const useSelectValue = () => useTemplatesSelectContext().value;

export const useOnFilterTemplatesByCategory = () =>
  useTemplatesSelectContext().onFilterTemplatesByCategory;

export const useOnFilterTemplatesByKeywords = () =>
  useTemplatesSelectContext().onFilterTemplatesByKeywords;

export const useOnTemplateSelected = () =>
  useTemplatesSelectContext().onTemplateSelected;
