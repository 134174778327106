import * as React from "react";
import MuiCard, { CardProps } from "@mui/material/Card";
import MuiCardContent, { CardContentProps } from "@mui/material/CardContent";
import MuiCardActionArea, {
  CardActionAreaProps,
} from "@mui/material/CardActionArea";
import truncate from "lodash/truncate";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import { useTheme } from "@mui/material/styles";
import { getStyleProps } from "modules/themes/styleUtils";
import { Typography } from "components/ui/Typography";
import { Box } from "components/ui/box";
import { Button, IconButton } from "components/ui/button";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { Avatar } from "components/ui/avatar";
import { Tooltip } from "components/ui/tooltip";
import { Author } from "client-server-shared/types/types";
import {
  ClientDeleteContext,
  Integration,
  Template,
} from "client-server-shared/types/collection";
import MuiCardMedia, { CardMediaProps } from "@mui/material/CardMedia";
import { stringToColor } from "utils/string-to-color";
import { NextLink } from "components/next-link";
import {
  templateServerRouteBySlug,
  workflowRouteBySlug,
} from "client-server-shared/config/routes";

export const Card = (props: CardProps) => {
  return (
    <MuiCard {...props} {...getStyleProps(props)}>
      {props.children}
    </MuiCard>
  );
};

export const CardMedia = (props: CardMediaProps) => {
  return <MuiCardMedia {...props} {...getStyleProps(props)} />;
};

export const CardContent = (props: CardContentProps) => {
  return (
    <MuiCardContent {...props} {...getStyleProps(props)}>
      {props.children}
    </MuiCardContent>
  );
};

export const CardActionArea = (props: CardActionAreaProps) => {
  return (
    <MuiCardActionArea {...props} {...getStyleProps(props)}>
      {props.children}
    </MuiCardActionArea>
  );
};

interface TemplateCardCompactPrimaryProps extends Template {
  icon?: React.ReactNode;
  title: string;
  onUse?: () => void;
  onClick?: () => void;
  selected?: boolean;
}

export const WorkflowCard = ({
  icon,
  title,
  cardDescription,
  slug,
  onClick = () => {},
  comingSoon,
  ...rest
}) => {
  const theme = useTheme();
  const Icon = icon;
  const iconColor = stringToColor(title);
  return (
    <Card
      component={"article"}
      sx={{
        textDecoration: "none",
        boxShadow:
          "rgba(17, 17, 26, 0.05) 0px 4px 16px, rgba(17, 17, 26, 0.05) 0px 8px 32px",
        borderRadius: "16px",
        margin: "10px",
        letterSpacing: "0.5px",
        position: "relative",
        "&:hover": {
          button: {
            opacity: 1,
            zIndex: 1,
          },
        },
        flexBasis: {
          xs: "100%",
          sm: `45%`,
          md: "45%",
          xl: "25%",
        },
        ...(rest.sx || {}),
      }}
    >
      {comingSoon && (
        <Box
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            zIndex: 2,
            right: 0,
            bottom: 0,
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography
            fontWeight={500}
            sx={{
              textShadow: "2px 2px 4px rgba(0, 0, 0, 0.5)",
            }}
            color={"text.white"}
          >
            Coming soon
          </Typography>
        </Box>
      )}
      <CardActionArea
        onClick={() => {
          if (!comingSoon) {
            onClick();
          }
        }}
        sx={{
          padding: "20px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          height: "100%",
          alignItems: "flex-start",
        }}
      >
        <Avatar
          variant="square"
          sx={{
            bgcolor: iconColor,
            borderRadius: "5px",
            zIndex: 1,
          }}
        >
          <Icon />
        </Avatar>
        <Typography
          marginTop={12}
          variant="h6"
          marginBottom={12}
          sx={{ fontWeight: 600 }}
        >
          {title}
        </Typography>
        <Box
          sx={{
            lineHeight: 1.2,
          }}
        >
          <Typography
            variant="body2"
            sx={{
              fontSize: "13px",
            }}
          >
            {cardDescription}
          </Typography>
        </Box>
      </CardActionArea>
    </Card>
  );
};

export const TemplateCardCompactPrimary = ({
  icon,
  title,
  cardDescription,
  onUse,
  asLink,
  selected,
  slug,
  component,
  sx = {},
  onClick = () => {},
}: TemplateCardCompactPrimaryProps) => {
  const theme = useTheme();
  const Icon = icon;
  const iconColor = stringToColor(title);
  return (
    <Card
      component={component || asLink ? NextLink : "article"}
      href={asLink ? templateServerRouteBySlug(slug) : ""}
      sx={{
        textDecoration: "none",
        boxShadow:
          "rgba(17, 17, 26, 0.05) 0px 4px 16px, rgba(17, 17, 26, 0.05) 0px 8px 32px",
        borderRadius: "16px",
        margin: "10px 10px 10px 10px",
        letterSpacing: "0.5px",
        position: "relative",
        "&:hover": {
          button: {
            opacity: 1,
            zIndex: 1,
          },
        },
        flexBasis: {
          xs: "100%",
          sm: `45%`,
          md: "45%",
          xl: "22%",
        },
        ...sx,
      }}
      className="with-hover-underline-effect-p"
    >
      <CardActionArea
        sx={{
          padding: "20px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          height: "100%",
          alignItems: "flex-start",
        }}
        onClick={onClick}
      >
        <Avatar
          variant="square"
          sx={{
            bgcolor: iconColor,
            borderRadius: "5px",
          }}
        >
          <Icon />
        </Avatar>
        <Typography
          marginTop={12}
          variant="h6"
          marginBottom={12}
          sx={{ fontWeight: 600 }}
        >
          {title}
        </Typography>
        <Box
          sx={{
            lineHeight: 1.2,
          }}
        >
          <Typography
            variant="body2"
            sx={{
              fontSize: "13px",
            }}
          >
            {cardDescription}
          </Typography>
        </Box>
      </CardActionArea>

      {onUse && (
        <Button
          onClick={onUse}
          sx={{
            position: "absolute",
            bottom: 0,
            right: 0,
            zIndex: -1,
            opacity: 0,
            transition: "0.5s",
          }}
        >
          Use
        </Button>
      )}
    </Card>
  );
};

export const AuthorCard = (
  props: Author & {
    onClick?: () => void | Promise<void>;
    categories?: string[];
    icon?: React.ReactElement | null;
    onDeleteAuthor: () => void | Promise<void>;
    onEdit: () => void;
    onUse?: () => void;
    selected?: boolean;
  } & ClientDeleteContext
) => {
  const {
    categories = [],
    onDeleteAuthor,
    name,
    designation,
    bio,
    onClick = () => {},
    onEdit,
    icon,
    onUse,
    selected,
    hardDelete,
    softDelete,
    deleteInfo,
  } = props;
  const maxCategoriesToShow = categories.slice(0, 2);
  const theme = useTheme();
  return (
    <Card
      component="article"
      sx={{
        border: `1px solid ${
          selected
            ? theme.palette.borders.highlight
            : theme.palette.borders.standard
        }`,
        borderRadius: "16px",
        margin: "10px 10px 10px 0px",
        height: "180px",
        width: "200px",
        letterSpacing: "0.5px",
        position: "relative",
        "&:hover": {
          button: {
            opacity: 1,
            zIndex: 1,
          },
        },
      }}
      className="with-hover-underline-effect-p"
    >
      <CardActionArea
        onClick={onClick}
        sx={{
          padding: "15px",
          height: "100%",
        }}
      >
        <Box sameLine alignCenter marginBottom={5}>
          {icon && <Avatar marginRight={10}>{icon}</Avatar>}
          <Box displayColumn>
            <Typography marginBottom={2} variant="h6" sx={{ fontWeight: 600 }}>
              {name}
            </Typography>
            {maxCategoriesToShow.length > 0 ? (
              <Tooltip title={categories.join(",")}>
                <Box sameLine alignCenter>
                  {maxCategoriesToShow.map((category, index) => (
                    <Typography
                      key={category}
                      component="span"
                      variant="body2"
                      marginRight={5}
                    >
                      {category}{" "}
                      {index === maxCategoriesToShow.length - 1 ? "" : " |"}
                    </Typography>
                  ))}
                </Box>
              </Tooltip>
            ) : null}
          </Box>
        </Box>
        <Typography variant="body2">
          {truncate(bio, {
            length: 70,
            separator: "...",
          })}
        </Typography>
      </CardActionArea>
      {onEdit && (
        <IconButton
          sx={{
            position: "absolute",
            top: 0,
            right: 0,
          }}
          onClick={onEdit}
        >
          <ModeEditOutlineOutlinedIcon size="small" />
        </IconButton>
      )}
      {softDelete || hardDelete ? (
        <Tooltip title={deleteInfo || ""}>
          <IconButton
            component="button"
            sx={{
              position: "absolute",
              bottom: 0,
              right: "50%",
              zIndex: -1,
              opacity: 0,
              transition: "0.5s",
              transform: "translateX(50%)",
            }}
            onClick={onDeleteAuthor}
          >
            <DeleteOutlineIcon size="small" />
          </IconButton>
        </Tooltip>
      ) : null}
      {onUse && (
        <Button
          onClick={onUse}
          sx={{
            position: "absolute",
            bottom: 0,
            right: 0,
            zIndex: -1,
            opacity: 0,
            transition: "0.5s",
          }}
        >
          Use
        </Button>
      )}
    </Card>
  );
};

interface TemplateCardProps extends Template {
  onClick?: () => void;
  feature?: boolean;
  route?: string;
}

export const TemplateCard = ({
  icon,
  title,
  shortDescription,
  onClick,
  feature,
  route,
}: TemplateCardProps) => {
  const theme = useTheme();

  if (feature) {
    return (
      <Card
        component="article"
        className="with-hover-underline-effect-p"
        padding={0}
        sx={{
          margin: "10px 20px 10px 0px",
          maxWidth: 330,
          "&:hover": {
            boxShadow: theme.boxShadows.colored.primary,
          },
        }}
        onClick={() => {
          if (onClick) {
            onClick();
          }
        }}
      >
        <CardActionArea sx={{ padding: 1 }}>
          <CardContent sameLine>
            {icon}
            <Box>
              <Typography gutterBottom variant="h6" component="div">
                {title}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                {shortDescription}
              </Typography>
            </Box>
          </CardContent>
        </CardActionArea>
      </Card>
    );
  }

  return (
    <Card
      component="article"
      sx={{
        margin: "10px 20px 10px 0px",
        maxWidth: 200,
        width: "100%",
        "&:hover": {
          boxShadow: theme.boxShadows.colored.primary,
        },
      }}
      onClick={() => {
        if (onClick) {
          onClick();
        }
        if (route) {
          navigate(route);
        }
      }}
    >
      <CardActionArea sx={{ padding: 1 }}>
        <CardContent>
          {icon && <Avatar marginBottom={15}>{icon}</Avatar>}
          <Typography gutterBottom variant="h6" component="div">
            {title}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {shortDescription}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

interface IntegrationCardProps extends Integration {
  icon?: React.ReactNode;
  onClick?: () => void;
  feature?: boolean;
  route?: string;
}

export const IntegrationCard = (props: IntegrationCardProps) => {
  const { icon, title, shortDescription, onClick, feature, route } = props;
  const theme = useTheme();

  if (feature) {
    return (
      <Card
        component="article"
        className="with-hover-underline-effect-p"
        padding={0}
        sx={{
          margin: "10px 20px 10px 0px",
          maxWidth: 330,
          "&:hover": {
            boxShadow: theme.boxShadows.colored.primary,
          },
        }}
        onClick={() => {
          if (onClick) {
            onClick();
          }
        }}
      >
        <CardActionArea sx={{ padding: 1 }}>
          <CardContent sameLine>
            {icon}
            <Box>
              <Typography gutterBottom variant="h6" component="div">
                {title}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                {shortDescription}
              </Typography>
            </Box>
          </CardContent>
        </CardActionArea>
      </Card>
    );
  }

  return (
    <Card
      component="article"
      sx={{
        margin: "10px 20px 10px 0px",
        maxWidth: 200,
        width: "100%",
        "&:hover": {
          boxShadow: theme.boxShadows.colored.primary,
        },
      }}
      onClick={() => {
        if (onClick) {
          onClick();
        }
        if (route) {
          navigate(route);
        }
      }}
    >
      <CardActionArea sx={{ padding: 1 }}>
        <CardContent>
          {icon && <Avatar marginBottom={15}>{icon}</Avatar>}
          <Typography gutterBottom variant="h6" component="div">
            {title}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {shortDescription}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

const CardOverlayPrimary = () => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        inset: "0px",
        zIndex: 1,
        position: "absolute",
        backgroundColor: theme.palette.overlay.primary,
      }}
    />
  );
};

interface CardHighlightPrimaryProps {
  tag: string;
  category: string;
  title: string;
  description: string;
  imageUrl: string;
  imageAlt?: string;
  height?: number;
}

export const CardHighlightPrimary = ({
  height,
  imageUrl,
  imageAlt,
  category,
  tag,
  title,
  description,
}: CardHighlightPrimaryProps) => {
  const safeAlt = imageAlt || category;
  return (
    <Box
      sx={{
        position: "relative",
        height: `100%`,
        width: "100%",
      }}
    >
      <CardOverlayPrimary />
      <Typography
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          opacity: 0.8,
          zIndex: 2,
          padding: "24px",
        }}
        color="text.white"
      >
        {tag}
      </Typography>
      <Box
        component="img"
        sx={{
          position: "absolute",
          height: "100%",
          width: "100%",
          objectFit: "cover",
        }}
        src={imageUrl}
        alt={safeAlt}
      />
      <Box
        sx={{
          display: "flex",
          position: "absolute",
          bottom: 0,
          left: 0,
          padding: "24px",
          flexDirection: "column",
          width: "100%",
          alignItems: "flex-start",
        }}
      >
        <Typography
          sx={{
            opacity: "0.5",
            zIndex: 2,
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            width: "100%",
          }}
          color="text.white"
        >
          {category}
        </Typography>
        <Typography
          sx={{
            zIndex: 2,
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            width: "100%",
          }}
          gutterBottom
          variant="h5"
          color="text.white"
        >
          {title}
        </Typography>
        <Typography
          sx={{
            zIndex: 2,
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            width: "100%",
          }}
          variant="body2"
          color="text.white"
        >
          {description}
        </Typography>
      </Box>
    </Box>
  );
};
