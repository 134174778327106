// @ts-nocheck

import { isCn } from "client-server-shared/constants";
import { TemplateType } from "templates/shared-templates/types";

const isDefined = (str?: string) => {
  return str && str.trim();
};

const rootFieldsToTranslate = [
  "title",
  "contentTypeLabel",
  "helpText",
  "defaultCollectionName",
  "collectionNamePlaceholder",
  "postNamePlaceholder",
  "defaultPostName",
  "cardDescription",
  "shortDescription",
  "caption",
];
const fieldFieldsToTranslate = ["label", "description", "placeholder", "info"];

const translate = (str: string, translations: Record<string, string>) => {
  return translations[str] || str;
};

export const translateTemplates = (
  templates: TemplateType[],
  translations: Record<string, string>
) => {
  if (!isCn()) {
    return templates;
  }
  return templates.map((template) => {
    const copyTemplate = {
      ...template,
    };

    rootFieldsToTranslate.forEach((rootField) => {
      const value = copyTemplate[rootField];
      if (isDefined(value)) {
        const translated = translate(value, translations);
        if (translated) {
          copyTemplate[rootField] = translated;
        }
      }
    });

    for (const key in copyTemplate.fields) {
      const field = copyTemplate.fields[key];

      fieldFieldsToTranslate.forEach((fieldField) => {
        const value = field[fieldField];
        if (isDefined(value)) {
          const translated = translate(value, translations);
          if (translated) {
            copyTemplate.fields[key][fieldField] = translated;
          }
        }
      });
      const autoCompleteSuggestions = field.autoCompleteSuggestions;
      if (autoCompleteSuggestions && autoCompleteSuggestions.length > 0) {
        const newAutoCompleteSuggestions = autoCompleteSuggestions.map(
          (suggestion) => {
            const translated = translate(suggestion.label, translations);

            if (translated) {
              return {
                ...suggestion,
                label: translated,
              };
            }
            return suggestion;
          }
        );
        field["autoCompleteSuggestions"] = newAutoCompleteSuggestions;
      }
    }

    return copyTemplate;
  });
};
