import { serverRoutes } from "client-server-shared/config/routes";
import { siteConfig } from "client-server-shared/config/siteConfig";
import { useTranslateTemplates } from "hooks/use-translate-templates";
import clientTemplates from "templates/client-templates/templates";
import { isCn, locale } from "client-server-shared/constants";
import { useTranslation } from "components/translate";
import { Box } from "components/ui/box";
import { Typography } from "components/ui/Typography";
import { TemplatesSelectContextProvider } from "context/templates-select-context";
import type { GetStaticProps } from "next";
import { serverSideTranslations } from "next-i18next/serverSideTranslations";
import { BreadcrumbJsonLd } from "next-seo";
import Script from "next/script";
import React from "react";
import { PageHeadTags } from "common/head-tags";
import { buildJsonLd } from "modules/marketing-pages/faq-to-json-ld";
import { Container } from "modules/marketing-pages/front-page/shared";
import { TemplateContext } from "modules/marketing-pages/templates/by-id/shared";
import TemplateForm from "modules/marketing-pages/templates/by-id/template-form";
import Faq, { useFaqLists } from "modules/marketing-pages/templates/faq";
import { translateMarketingTemplates } from "client-server-shared/template-data/translate";
import { PrimaryHero } from "modules/non-protected-page/heros/primary-hero";
import { headerHeight } from "modules/non-protected-page/shared";
import { TemplatesCompactLibrary } from "modules/templates/templates-compact-library";
import { wrapper } from "reducers";
import { translationLoaded } from "reducers/template-translation";
import { PrimaryInput } from "components/input";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import InputAdornment from "@mui/material/InputAdornment";
import {
  useOnFilterTemplatesByCategory,
  useOnFilterTemplatesByKeywords,
  useSelectSelectedCategory,
  useSelectTemplates,
  useSelectValue,
  useSelectSelectedTemplateIds,
  useSelectTemplateIds,
  useOnTemplateSelected,
} from "context/templates-select-context";

const Hero = React.memo(() => {
  const { t } = useTranslation("templates");
  const { translates } = useTranslateTemplates();
  const value = useSelectValue();
  const onFilterTemplatesByKeywords = useOnFilterTemplatesByKeywords();

  const onInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onFilterTemplatesByKeywords(event.target.value);
  };
  return (
    <PrimaryHero
      primaryText="Instant Content Creation with AI - No Login Required"
      secondaryText={t("Save Hours Of Work with Over 50 AI Content Templates")}
      wrapperProps={{
        sx: {
          textAlign: "center",
          padding: "30px 0 148px 0",
        },
      }}
      slot={{
        main: (
          <>
            <PrimaryInput
              fullWidth
              value={value}
              size="medium"
              onChange={onInputChange}
              sx={{
                marginTop: "48px",
                "& .MuiInputBase-root": {
                  background: "white",
                  color: "#7B7B7B",
                  padding: "6px 12px",
                  fontSize: {
                    xs: "14px",
                    sm: "14px",
                    md: "16px",
                    lg: "22px",
                  },
                  borderRadius: "16px",
                },
              }}
              placeholder={t("What do you want to generate?")}
              InputProps={{
                sx: {},
                endAdornment: (
                  <InputAdornment position="end">
                    <SearchOutlinedIcon />
                  </InputAdornment>
                ),
              }}
            />
          </>
        ),
      }}
    />
  );
});

const TemplatesPage = ({ template }) => {
  const { t } = useTranslation("templates");
  const faqLists = useFaqLists();
  return (
    <TemplateContext.Provider value={{ template: template }}>
      <TemplatesSelectContextProvider
        clientTemplates={clientTemplates.filter(
          (template) => template.slug !== "unique-content-ideas"
        )}
        onTemplateSelected={() => {}}
      >
        <PageHeadTags
          pageLink={serverRoutes.front}
          title={t("Instant Content Creation with AI - No Login Required")}
          description={t(
            "Instantly generate over 50 types of content without the hassle of logging in. Save time and boost creativity."
          )}
        />
        <Hero />
        <Container
          maxWidth="xl"
          sx={{
            padding: "72px 12px",
            minHeight: "100vh",
          }}
        >
          <Box
            sx={{
              "& p": {
                background: "none!important",
              },
            }}
          >
            <TemplatesCompactLibrary asLink />
          </Box>
        </Container>
      </TemplatesSelectContextProvider>
    </TemplateContext.Provider>
  );
};

export default TemplatesPage;

export const getStaticProps: GetStaticProps = wrapper.getStaticProps(
  (store) => async () => {
    if (isCn()) {
      const translations = (await import(`templates/shared-templates/cn.json`))
        .default;
      store.dispatch(translationLoaded(translations));
    }
    const template = (
      await import("client-server-shared/template-data/initial-data")
    ).default;
    return {
      props: {
        template: await translateMarketingTemplates(template),
        ...(await serverSideTranslations(locale, ["templates", "common"])),
        // Will be passed to the page component as props
      },
    };
  }
);
