import { translateTemplates } from "templates/client-templates/translate-templates";
import { TemplateType } from "templates/shared-templates/types";
import React from "react";
import { selectTemplateTranslations } from "../selectors/template-translations";
import { useSelector } from "./use-selector";

export const useTranslateTemplates = () => {
  const translations = useSelector(selectTemplateTranslations);

  const translates = React.useCallback(
    (templates: TemplateType[]) => {
      return translateTemplates(templates, translations);
    },
    [translations]
  );
  const translate = React.useCallback(
    (template: TemplateType) => {
      return translateTemplates([template], translations)[0];
    },
    [translations]
  );
  return { translates, translate };
};
